@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes border-slide {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

body {
  @apply font-urbanista;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.border-animation {
  position: relative;
}

.border-animation::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #e9831e;
  width: 0;
  transition: width 0.5s ease-in-out;
}

.border-animation:hover::after {
  width: 100%;
}

.border-animation.active::after {
  width: 100%;
  background-color: #f84741;
}

.topmenu a {
  @apply mr-4 text-white font-bold border-animation
}

.sidemenu {
  @apply z-[1] mt-3 w-52 p-2 shadow bg-green-700 flex flex-col justify-start items-start gap-5
}

.sidemenu a {
  @apply text-gray-300
}

.sidemenu a.active {
  @apply text-white underline
}

.pagehead {
  @apply shadow-lg font-bold text-black w-full px-5 lg:w-72 mx-auto p-2 mt-5 rounded text-center;
}

.table-filter-icon {
  @apply inline text-gray-200 bg-green-500 rounded-sm text-xl p-1 hover:bg-gray-700 hover:text-gray-200;
}